<template>
  <div
    class="
      login-regiter-card
      m-auto
      p-8
      ant-card ant-card-bordered
      shadow
      rounded-md
    "
  >
    <div class="mb-6 text-center">
      <AppLogo />
    </div>

    <h1 class="text-center text-xl mb-6">ログイン</h1>
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
      layout="vertical"
    >
      <a-form-item :label="$t('email')" name="email">
        <a-input v-model:value="form.email" autocomplete="email">
          <template v-slot:prefix>
            <MailOutlined />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item :label="$t('password')" name="password">
        <a-input
          v-model:value="form.password"
          type="password"
          autocomplete="current-password"
        >
          <template v-slot:prefix>
            <LockOutlined />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item class="mb-4">
        <a-button block type="primary" html-type="submit" @click="handleSubmit">
          ログイン
        </a-button>
      </a-form-item>

      <a-form-item>
        <ButtonLoginGoogle />
      </a-form-item>
      <a-form-item class="m-0 text-center">
        <a @click="onGotoForgotPassword()"> パスワードをお忘れですか？ </a>
      </a-form-item>
    </a-form>
  </div>
  <div class="text-center mt-3">
    まだアカウトを持ってないですか？
    <a @click="onGotoRegister()"> 登録 </a>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Modal } from 'ant-design-vue'
import { maska } from 'maska'
import { MailOutlined, LockOutlined } from '@ant-design/icons-vue'
import { LOGIN } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import ButtonLoginGoogle from '@/components/account/ButtonLoginGoogle.vue'
import AppLogo from '@/components/common/AppLogo.vue'

@Options({
  components: { AppLogo, MailOutlined, LockOutlined, ButtonLoginGoogle },
  directives: { maska },
})
export default class LoginForm extends mixins(LoginRegisterMixin) {
  form = {
    email: '',
    password: '',
  }

  rules = {
    email: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
  }

  isActive = false

  get joiningToken() {
    return this.$store.state.profile.joining_token
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  async handleSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doLogin()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doLogin() {
    const { email, password } = this.form
    const { dispatch } = this.$store

    if (email && password) {
      await dispatch(LOGIN, {
        email,
        password,
      }).catch(() => {
        errorHandler(['メールアドレスまたはパスワードが正しくありません。'])
      })
    }
  }

  openCommingSoon() {
    Modal.confirm({
      title: '',
      content: 'Comming soon...',
      okText: '了解',
      cancelText: 'キャンセル',
    })
  }

  onGotoRegister() {
    this.$router.push({ name: 'register' })
  }

  onGotoForgotPassword(): void {
    this.openCommingSoon()
    // this.$router.push({ name: 'forgot_password' })
  }
}
</script>
