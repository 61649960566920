
import { Options, mixins } from 'vue-class-component'
import { Modal } from 'ant-design-vue'
import { maska } from 'maska'
import { MailOutlined, LockOutlined } from '@ant-design/icons-vue'
import { LOGIN } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import ButtonLoginGoogle from '@/components/account/ButtonLoginGoogle.vue'
import AppLogo from '@/components/common/AppLogo.vue'

@Options({
  components: { AppLogo, MailOutlined, LockOutlined, ButtonLoginGoogle },
  directives: { maska },
})
export default class LoginForm extends mixins(LoginRegisterMixin) {
  form = {
    email: '',
    password: '',
  }

  rules = {
    email: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        message: 'この項目が必須です。',
        trigger: 'blur',
      },
    ],
  }

  isActive = false

  get joiningToken() {
    return this.$store.state.profile.joining_token
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  async handleSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doLogin()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doLogin() {
    const { email, password } = this.form
    const { dispatch } = this.$store

    if (email && password) {
      await dispatch(LOGIN, {
        email,
        password,
      }).catch(() => {
        errorHandler(['メールアドレスまたはパスワードが正しくありません。'])
      })
    }
  }

  openCommingSoon() {
    Modal.confirm({
      title: '',
      content: 'Comming soon...',
      okText: '了解',
      cancelText: 'キャンセル',
    })
  }

  onGotoRegister() {
    this.$router.push({ name: 'register' })
  }

  onGotoForgotPassword(): void {
    this.openCommingSoon()
    // this.$router.push({ name: 'forgot_password' })
  }
}
